var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "suggestions-form-comp" },
    [
      _c(
        "el-form",
        {
          ref: "ruleForm",
          staticClass: "demo-ruleForm",
          attrs: {
            model: _vm.ruleForm,
            rules: _vm.rules,
            "label-position": "top",
            "label-width": "100px",
          },
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "反馈类型", prop: "feedbackType" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: {
                        disabled:
                          _vm.currentState === "view" ||
                          _vm.currentState === "deal",
                      },
                      model: {
                        value: _vm.ruleForm.feedbackType,
                        callback: function ($$v) {
                          _vm.$set(_vm.ruleForm, "feedbackType", $$v)
                        },
                        expression: "ruleForm.feedbackType",
                      },
                    },
                    _vm._l(_vm.feedbackList, function (item, index) {
                      return _c("el-option", {
                        key: index,
                        attrs: { value: item.value, label: item.label },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            { attrs: { gutter: 48 } },
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系人", prop: "liaison" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled:
                            _vm.currentState === "view" ||
                            _vm.currentState === "deal",
                        },
                        model: {
                          value: _vm.ruleForm.liaison,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "liaison", $$v)
                          },
                          expression: "ruleForm.liaison",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "联系电话", prop: "contactWay" } },
                    [
                      _c("el-input", {
                        attrs: {
                          disabled:
                            _vm.currentState === "view" ||
                            _vm.currentState === "deal",
                        },
                        model: {
                          value: _vm.ruleForm.contactWay,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "contactWay", $$v)
                          },
                          expression: "ruleForm.contactWay",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "内容描述", prop: "contents" } },
                [
                  _c("el-input", {
                    attrs: {
                      disabled:
                        _vm.currentState === "view" ||
                        _vm.currentState === "deal",
                      type: "textarea",
                    },
                    model: {
                      value: _vm.ruleForm.contents,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "contents", $$v)
                      },
                      expression: "ruleForm.contents",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-row",
            [
              _c(
                "el-form-item",
                { attrs: { label: "上传图片", prop: "imgIds" } },
                [
                  _c(
                    "el-upload",
                    {
                      attrs: {
                        disabled:
                          _vm.currentState === "view" ||
                          _vm.currentState === "deal",
                        "with-credentials": true,
                        action: _vm.uploadPath,
                        "list-type": "picture-card",
                        "file-list": _vm.fileList,
                        "before-upload": _vm.beforeAvatarUpload,
                        "on-success": _vm.handleAvatarSuccess,
                        "on-remove": _vm.handleRemove,
                        "on-preview": _vm.handlePictureCardPreview,
                      },
                    },
                    [_c("i", { staticClass: "el-icon-plus" })]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.currentState === "deal" ||
          (_vm.currentState === "view" && _vm.disposeStatus === 1) ||
          (_vm.currentState === "edit" && _vm.disposeStatus === 1)
            ? _c(
                "el-row",
                [
                  _c("el-divider", { attrs: { "content-position": "left" } }, [
                    _vm._v("处理内容"),
                  ]),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "处理结果",
                        prop: "disposeResult",
                        rules: [
                          {
                            required:
                              _vm.currentState === "deal" ||
                              _vm.currentState === "edit",
                            message: "请填写处理结果",
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          disabled: _vm.currentState === "view",
                        },
                        model: {
                          value: _vm.ruleForm.disposeResult,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "disposeResult", $$v)
                          },
                          expression: "ruleForm.disposeResult",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.currentState !== "view"
            ? _c(
                "el-form-item",
                { attrs: { align: "right" } },
                [
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: {
                        click: function ($event) {
                          return _vm.submitForm("ruleForm")
                        },
                      },
                    },
                    [_vm._v("确定")]
                  ),
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          return _vm.resetForm()
                        },
                      },
                    },
                    [_vm._v("取消")]
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.dialogVisible, modal: false },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c("img", {
            attrs: { width: "100%", src: _vm.dialogImageUrl, alt: "" },
          }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }