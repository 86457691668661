<template>
    <div class="suggestions-form-comp">
      <el-form
        :model="ruleForm"
        :rules="rules"
        label-position="top"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-row>
          <el-form-item label="反馈类型" prop="feedbackType">
            <el-select
              :disabled="currentState === 'view' || currentState === 'deal'"
              style="width: 100%"
              v-model="ruleForm.feedbackType"
            >
              <el-option
                v-for="(item, index) in feedbackList"
                :value="item.value"
                :label="item.label"
                :key="index"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-row>
        <el-row :gutter="48">
          <el-col :span="12">
            <el-form-item label="联系人" prop="liaison">
              <el-input
                :disabled="currentState === 'view' || currentState === 'deal'"
                v-model="ruleForm.liaison"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="联系电话" prop="contactWay">
              <el-input
                :disabled="currentState === 'view' || currentState === 'deal'"
                v-model="ruleForm.contactWay"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item label="内容描述" prop="contents">
            <el-input
              :disabled="currentState === 'view' || currentState === 'deal'"
              type="textarea"
              v-model="ruleForm.contents"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-form-item label="上传图片" prop="imgIds">
            <!-- <el-tooltip
              class="item"
              effect="dark"
              content="最多上传9张图片！"
              placement="top"
            > -->
            <el-upload
              :disabled="currentState === 'view' || currentState === 'deal'"
              :with-credentials="true"
              :action="uploadPath"
              list-type="picture-card"
              :file-list="fileList"
              :before-upload="beforeAvatarUpload"
              :on-success="handleAvatarSuccess"
              :on-remove="handleRemove"
              :on-preview="handlePictureCardPreview"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
            <!-- </el-tooltip> -->
  
            <!-- <div class="tips-container">
                <div>
                  <div>
                    温馨提示：最多上传1张，图片大小不能超过2m，图片格式为jpg，png。
                  </div>
                  <div>图片尺寸100:100</div>
                </div>
                <div
                  v-show="
                    currentState !== 'view' &&
                    currentState !== 'deal' &&
                    ruleForm.imgIds.length > 0
                  "
                  class="delete-icon"
                  @click="deleteHandler"
                >
                  <i class="el-icon-delete"></i>
                </div>
              </div> -->
            <!-- </div> -->
          </el-form-item>
        </el-row>
        <el-row
          v-if="
            currentState === 'deal' ||
            (currentState === 'view' && disposeStatus === 1) ||
            (currentState === 'edit' && disposeStatus === 1)
          "
        >
          <el-divider content-position="left">处理内容</el-divider>
          <el-form-item
            label="处理结果"
            prop="disposeResult"
            :rules="[
              {
                required: currentState === 'deal' || currentState === 'edit',
                message: '请填写处理结果',
                trigger: 'blur',
              },
            ]"
          >
            <el-input
              type="textarea"
              :disabled="currentState === 'view'"
              v-model="ruleForm.disposeResult"
            ></el-input>
          </el-form-item>
        </el-row>
        <el-form-item align="right" v-if="currentState !== 'view'">
          <el-button type="primary" @click="submitForm('ruleForm')"
            >确定</el-button
          >
          <el-button @click="resetForm()">取消</el-button>
        </el-form-item>
        <!-- <el-form-item align="right" v-if="currentState === 'view'">
          <el-button @click="changeStateToEdit" type="primary">编辑</el-button>
        </el-form-item> -->
      </el-form>
      <el-dialog :visible.sync="dialogVisible" :modal="false">
        <img width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
  </template>
  
  <script>
  import {
    addComplaintAdvice,
    getComplaintAdviceDetail,
    updateComplaintAdviceDetail,
    handleComplaintAdvice,
  } from "@/api/ruge/jwPark/suggestions";
  import { envInfo } from "@/constants/envInfo";
  export default {
    props: {
      feedbackList: {
        type: Array,
        required: true,
      },
      dialogStatus: {
        type: String,
        required: true,
      },
      rowId: {
        required: false,
      },
      disposeStatus: {
        required: false,
      },
    },
    watch: {
      dialogStatus: {
        handler(val) {
          this.currentState = val;
        },
        immediate: true,
      },
      rowId: {
        handler(id) {
          if (!id) return;
          this.getSuggestionDetail(id);
        },
        immediate: true,
      },
    },
    data() {
      var validatorPhone = function (rule, value, callback) {
        if (value === "") {
          callback(new Error("请填写联系电话"));
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error("联系电话格式错误"));
        } else {
          callback();
        }
      };
      return {
        dialogImageUrl: "",
        dialogVisible: false,
        currentState: "add",
        uploadPath:
          envInfo.bgApp.archivePath +
          "/param/archive/upload?ulType=DefaultUpload&scopeType=ALL",
        fileList: [],
        ruleForm: {
          feedbackType: "",
          liaison: "",
          contactWay: "",
          contents: "",
          imgIds: [],
          disposeResult: "",
        },
        rules: {
          feedbackType: [
            { required: true, message: "请选择反馈类型", trigger: "blur" },
          ],
          liaison: [{ required: true, message: "请填写联系人", trigger: "blur" }],
          contactWay: [
            { required: true, validator: validatorPhone, trigger: "blur" },
          ],
          contents: [
            { required: true, message: "请填写内容描述", trigger: "blur" },
          ],
        },
      };
    },
    methods: {
      handlePictureCardPreview(file) {
        this.dialogImageUrl = file.url;
        this.dialogVisible = true;
      },
      getSuggestionDetail(id) {
        getComplaintAdviceDetail({ id }).then((res) => {
          // this.ruleForm = { ...this.ruleForm, ...res.data };
          console.log("this.ruleForm", this.ruleForm);
          this.ruleForm.feedbackType = res.data.feedbackType.toString();
          this.ruleForm.liaison = res.data.liaison;
          this.ruleForm.contactWay = res.data.contactWay;
          this.ruleForm.contents = res.data.contents;
          this.ruleForm.imgIds = res.data.imgIds;
          this.ruleForm.disposeResult = res.data.disposeResult;
          this.ruleForm.disposeStatus = res.data.disposeStatus;
          this.ruleForm.orderId = res.data.orderId;
          // url回显用
          // response 给编辑的时候remove时用
          this.fileList = res.data.imgIds.map((item) => {
            return {
              url: `${envInfo.bgApp.archivePath}/param/archive/download?dlType=DefaultDownload&fType=image&fi=${item}`,
              response: [{ fileId: item }],
            };
          });
        });
      },
      changeStateToEdit() {
        this.currentState = "edit";
      },
      handleRemove(file, fileList) {
        this.ruleForm.imgIds = fileList.map((item) => item.response[0].fileId);
      },
      submitForm(formName) {
        this.$refs[formName].validate((valid) => {
          if (valid) {
            this.submitHandler();
          } else {
            console.log("error submit!!");
            return false;
          }
        });
      },
      resetForm() {
        this.$refs["ruleForm"].resetFields();
        this.$emit("close", false);
      },
      submitHandler() {
        let params = { ...this.ruleForm };
        if (this.currentState === "add") {
          addComplaintAdvice(params).then((res) => {
            if (res.code === 200) {
              this.$message.success("添加成功！");
              this.$emit("close", true);
            } else {
              this.$message.warning("添加失败！");
            }
          });
        } else if (this.currentState === "edit") {
          params.id = this.rowId;
          updateComplaintAdviceDetail(params).then((res) => {
            if (res.code === 200) {
              this.$message.success("更新成功！");
              this.$emit("close", true);
            } else {
              this.$message.warning("更新失败！");
            }
          });
        } else if (this.currentState === "deal") {
          const params = {
            id: this.rowId,
            disposeResult: this.ruleForm.disposeResult,
            orderId: this.ruleForm.orderId
          };
          handleComplaintAdvice(params).then((res) => {
            if (res.code === 200) {
              this.$message.success("处理成功！");
              this.$emit("close", true);
            } else if(res.code === 500) {
              this.$message.error(res.msg);
            } 
          });
        }
      },
      handleAvatarSuccess(files) {
        this.ruleForm.imgIds.push(files[0].fileId);
      },
      beforeAvatarUpload(file) {
        console.log("this.fileList", this.ruleForm.imgIds.length);
        if (this.ruleForm.imgIds.length >= 9) {
          this.$message.error("最多上传9张图片!");
          return false;
        }
        const isJPG = file.type === "image/jpeg";
        const isPNG = file.type === "image/png";
        const isLt2M = file.size / 1024 / 1024 < 2;
  
        if (!isJPG && !isPNG) {
          this.$message.error("上传图片只能是 JPG,PNG 格式!");
        }
        if (!isLt2M) {
          this.$message.error("上传图片大小不能超过 2MB!");
        }
        const returnFlag = (isJPG || isPNG) && isLt2M;
        return returnFlag;
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .suggestions-form-comp {
    ::v-deep .el-upload-list__item-status-label {
      display: none;
    }
  }
  </style>