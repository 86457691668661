import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 投诉建议 - 分页查询
 * @param params
 */
export function getSuggestionsList(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/complaintAdvice/queryComplaintAdviceList',
      method: 'get',
      params,
    });
  }

  /**
 * 企业黄页 - 新增
 * @param params
 */
  export function addComplaintAdvice(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/complaintAdvice/addComplaintAdvice',
      method: 'post',
      data: params,
    });
  }

  /**
 * 投诉建议 - 获取详情
 * @param params
 */
export function getComplaintAdviceDetail(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/complaintAdvice/getDetails',
      method: 'get',
      params,
    });
  }

  /**
 * 投诉建议 - 修改
 * @param params
 */
export function updateComplaintAdviceDetail(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/complaintAdvice/updateComplaintAdvice',
      method: 'post',
      data: params,
    });
  }
  /**
 * 投诉建议 - 处理
 * @param params
 */
export function handleComplaintAdvice(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/complaintAdvice/handleComplaintAdvice',
      method: 'post',
      data: params,
    });
  }

  /**
 * 投诉建议 - 删除
 * @param params
 */
export function deleteComplaintAdvice(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/complaintAdvice/deleteComplaintAdvice',
      method: 'post',
      data: params,
    });
  }

    /**
 * 投诉建议 - 获取详情
 * @param params
 */
export function suggestionsListExport(params) {
    return request({
      url: envInfo.bgApp.customerServiceJWPath + '/complaintAdvice/export',
      method: 'get',
      params,
    });
  }