<template>
    <div class="app-container customer-suggestion-comp">
      <div class="filter-line">
        <span>
          <!-- <el-button type="primary" @click="addNewHandler">{{
            this.$t("commons.addNew")
          }}</el-button> -->
          <!-- <el-tooltip
            class="item"
            effect="dark"
            :disabled="suggestionObj.selectionList.length > 0"
            :content="$t('commons.multipleSelectionOne')"
            placement="right"
          >
            <el-button
              type="danger"
              @click="batchDeleteHandler"
              :class="
                suggestionObj.selectionList.length === 0 && 'not-allowed-button'
              "
              icon="el-icon-delete"
              plain
              >{{ $t("commons.delete") }}
            </el-button>
          </el-tooltip> -->
          <el-button type="primary" @click="exportHandler">{{
            this.$t("commons.export")
          }}</el-button>
        </span>
        <div
          class="header-filter-button"
          @click="dataset.filterLine.show = !dataset.filterLine.show"
          :class="
            dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
          "
        >
          <i class="iconfont icon-loudoutu"></i>
          筛选
        </div>
      </div>
      <div>
        <finalTable
          ref="finalTableRef"
          :datas="dataset"
          @tableEventHandler="tableEventHandler"
        />
      </div>
  
      <!-- 新增&编辑弹框 -->
      <el-dialog
        :visible.sync="suggestionObj.dialogVisible"
        :before-close="handleClose"
        width="728px"
        :destroy-on-close="true"
        :close-on-click-modal="false"
      >
        <template slot="title">
          <span>{{ suggestionObj.dialogTitle }}</span>
        </template>
        <suggestionsForm
          v-if="suggestionObj.dialogVisible"
          :feedbackList="feedbackList"
          :dialogStatus="suggestionObj.dialogStatus"
          :rowId="suggestionObj.rowId"
          :disposeStatus="suggestionObj.disposeStatus"
          @close="successAndClose"
        />
      </el-dialog>
    </div>
  </template>
  
  <script>
  import finalTable from "@/components/FinalTable";
  import { findLookupItem } from "@/api/ruge/lookup/lookup";
  import suggestionsForm from "./suggestionsForm";
  import {
    getSuggestionsList,
    deleteComplaintAdvice,
    suggestionsListExport
  } from "@/api/ruge/jwPark/suggestions";
  export default {
    components: {
      finalTable,
      suggestionsForm,
    },
    data() {
      return {
        feedbackList: [],
        dataset: {
          paginationConfig: {
            need: true,
          },
          filterLine: {
            show: true,
          },
          selection: {
            need: true,
            prop: "productId",
            width: "18",
          },
          header: [
            { prop: "adviceNo", label: "单号", width: "" },
            { prop: "feedbackType", label: "反馈类型", width: "" },
            { prop: "liaison", label: "联系人", width: "" },
            { prop: "contactWay", label: "电话号码", width: "" },
            { prop: "contentsResource", label: "投诉来源", width: "" },
            { prop: "disposeStatus", label: "处理状态", width: "" },
            { prop: "createTime", label: "投诉时间", width: "" },
            { prop: "disposePerson", label: "处理人", width: "" },
            { prop: "disposeTime", label: "处理时间", width: "" },
            { prop: "operation", label: "操作", width: "100" },
          ],
          tableData: [],
          // 搜索行配置
          searchLineConfig: {
            adviceNo: {
              type: "input",
              label: "单号",
              value: "",
              actionType: "goSearch",
              placeholder: "请输入单号",
              prefixIcon: "el-icon-search",
            },
            feedbackType: {
              type: "selector",
              label: "反馈类型",
              value: "",
              placeholder: "请选择反馈类型",
              actionType: "goSearch",
              optionList: [],
            },
            disposeStatus: {
              type: "selector",
              label: "处理状态",
              actionType: "goSearch",
              value: "",
              placeholder: "请选择处理状态",
              optionList: [
                {
                  label: "未处理",
                  value: "0",
                },
                {
                  label: "已处理",
                  value: "1",
                },
              ],
            },
            operation: {
              type: "button",
              filterCount: "",
              actionType: "clickEvent",
              eventName: "showMoreFilters",
              label: "更多筛选",
            },
          },
          // 表格内容配置
          detailConfig: {
            feedbackType: {
              type: "enumerationColumn",
              emuList: {},
            },
            contentsResource: {
              type: "enumerationColumn",
              emuList: {},
            },
            disposeStatus: {
              type: "enumerationColumn",
              emuList: {
                0: "未处理",
                1: "已处理",
              },
            },
            createTime: {
              type: "dateFormat",
            },
            disposeTime: {
              type: "dateFormat",
            },
            operation: {
              type: "icon",
              iconList: [
                {
                  actionType: "iconClick",
                  eventName: "deal",
                  fontSize: "14px",
                  color: "#1A4CEC",
                  cursorPointer: "pointer",
                  iconName: "el-icon-document-copy",
                  tooltips: "处理",
                  disableProp: "disposeStatus",
                  disableValue: 1,
                },
                {
                  actionType: "iconClick",
                  eventName: "view",
                  fontSize: "14px",
                  color: "#1A4CEC",
                  cursorPointer: "pointer",
                  iconName: "el-icon-view",
                  tooltips: "详情",
                },
                // {
                //   actionType: "iconClick",
                //   eventName: "edit",
                //   fontSize: "14px",
                //   color: "#1A4CEC",
                //   cursorPointer: "pointer",
                //   iconName: "el-icon-edit",
                //   tooltips: "编辑",
                // },
                // {
                //   actionType: "iconClick",
                //   eventName: "delete",
                //   fontSize: "14px",
                //   color: "#E47470",
                //   cursorPointer: "pointer",
                //   iconName: "el-icon-delete",
                //   tooltips: "删除",
                // },
              ],
            },
          },
          // 高级搜索配置
          advanceFilterConfig: {
            adviceNo: {
              inline: true,
              value: "",
            },
            feedbackType: {
              inline: true,
              value: "",
            },
            disposeStatus: {
              inline: true,
              value: "",
            },
            createTime: {
              type: "dateRange",
              label: "投诉时间",
              value: [],
            },
          },
          pageVO: {
            current: 1,
            rowCount: 10,
            total: 0,
          },
        },
        suggestionObj: {
          selectionList: [],
          dialogVisible: false,
          dialogStatus: "add",
          dialogTitle: "新增投诉建议",
          rowId: "",
          disposeStatus: null,
          tableLoading: false,
          listQuery: {
            current: 1,
            rowCount: 10,
            endTime: null,
            startTime: null,
            disposeStatus: null,
            adviceNo: "",
            feedbackType: "",
          },
        },
        handlerRules: {
          disposeResult: [
            { required: true, message: "请填写处理结果", trigger: "blur" },
          ],
        },
      };
    },
    watch: {
      suggestionObj: {
        handler(val) {
          console.log("val", val.tableLoading);
          this.$refs.finalTableRef.loadingToggle(val.tableLoading);
        },
        deep: true,
      },
    },
    created() {
      this.dataInit();
    },
    methods: {
        exportHandler() {
        suggestionsListExport(this.suggestionObj)
          .then((taskId) => {
            this.$message({
              type: "success",
              message: this.$t("message.operationSuccess"),
            });
            let exportObj = {
              taskId,
              taskName: "投诉建议",
              taskStatus: 0,
              rootPath: "gradePath",
            };
            this.$store.dispatch("PushExportNotice", exportObj);
          })
          .catch(() => {
            this.$message.warning("导出失败！");
          });
      },
      batchDeleteHandler() {
        if (this.suggestionObj.selectionList.length === 0) return;
        this.deleteHandler(this.suggestionObj.selectionList);
      },
      deleteHandler(ids) {
        this.$confirm(
          "删除后将不可恢复，确定删除么？",
          this.$t("commons.warning"),
          {
            confirmButtonText: this.$t("commons.confirm"),
            cancelButtonText: this.$t("commons.cancel"),
            type: "warning",
          }
        )
          .then(() => {
            deleteComplaintAdvice(ids).then((res) => {
              if (res.code === 200) {
                this.$message.success("删除成功！");
                this.getTableList();
              } else {
                this.$message.warning("删除失败！");
              }
            });
          })
          .catch((error) => {
            console.log(`未删除，原因 => ${error}`);
          });
      },
      successAndClose(flag) {
        this.suggestionObj.dialogVisible = false;
        flag && this.getTableList();
      },
      handleClose() {
        this.suggestionObj.dialogVisible = false;
      },
      dataInit() {
        this.getTableList();
        this.getFeedbackType();
        this.getResourceType();
      },
      // 数据字典 - 获取反馈类型
      getFeedbackType() {
        findLookupItem("JW_TRADE_TYPE").then((row) => {
          let feedbackTypeObj = {};
          this.dataset.searchLineConfig.feedbackType.optionList = row.map(
            (item) => {
              feedbackTypeObj[item.itemCode] = item.itemName;
              this.feedbackList.push({
                value: item.itemCode,
                label: item.itemName,
              });
              return {
                value: item.itemCode,
                label: item.itemName,
              };
            }
          );
  
          this.dataset.detailConfig.feedbackType.emuList = feedbackTypeObj;
        });
      },
      // 数据字典 - 获取投诉来源
      getResourceType() {
        findLookupItem("CUST_DATA_SOURCE").then((row) => {
          let feedbackTypeObj = {};
          row.forEach((item) => {
            feedbackTypeObj[item.itemCode] = item.itemName;
          });
          this.dataset.detailConfig.contentsResource.emuList = feedbackTypeObj;
        });
      },
      getTableList() {
        this.suggestionObj.tableLoading = true;
        const params = { ...this.suggestionObj.listQuery };
        for (let pn in params) {
          !params[pn] && params[pn] !== 0 && (params[pn] = null);
        }
        getSuggestionsList(params)
          .then((res) => {
            this.dataset.pageVO.total = res.data.total;
            this.dataset.tableData = res.data.rows;
          })
          .finally(() => {
            this.suggestionObj.tableLoading = false;
          });
      },
      tableEventHandler(datas) {
        if (datas.type === "goSearch") {
          if (datas.params.createTime && datas.params.createTime.length > 0) {
            this.suggestionObj.listQuery.startTime = datas.params.createTime[0]
              ? new Date(datas.params.createTime[0]).getTime()
              : null;
            this.suggestionObj.listQuery.endTime = datas.params.createTime[1]
              ? new Date(datas.params.createTime[1]).getTime()
              : null;
            delete datas.params.createTime;
          }
          // else {
          //   this.suggestionObj.listQuery.endTime = null;
          //   this.suggestionObj.listQuery.startTime = null;
          // }
          this.suggestionObj.listQuery = {
            ...this.suggestionObj.listQuery,
            ...datas.params,
          };
          this.suggestionObj.listQuery.current = 1;
          this.getTableList();
        } else if (datas.type === "paginationChange") {
          this.suggestionObj.listQuery.current = datas.params.current.page;
          this.suggestionObj.listQuery.rowCount = datas.params.current.limit;
          this.getTableList();
        } else if (datas.type === "updateSelectionList") {
          this.suggestionObj.selectionList = datas.list
            .filter((item) => item.id)
            .map((item) => item.id);
        } else if (datas.type === "switchEvent") {
          // this.updateDynamicRegistration(datas.row);
        } else if (datas.type === "iconClick") {
          console.log("datas.row.disposeStatus", datas.row.disposeStatus);
          switch (datas.eventName) {
            case "deal":
              this.suggestionObj.rowId = datas.row.id;
              this.suggestionObj.dialogTitle = "处理投诉建议";
              this.suggestionObj.dialogStatus = "deal";
              this.suggestionObj.disposeStatus = datas.row.disposeStatus;
              this.suggestionObj.dialogVisible = true;
              break;
            case "edit":
              this.suggestionObj.rowId = datas.row.id;
              this.suggestionObj.dialogTitle = "编辑投诉建议";
              this.suggestionObj.dialogStatus = "edit";
              this.suggestionObj.disposeStatus = datas.row.disposeStatus;
              this.suggestionObj.dialogVisible = true;
              break;
            case "view":
              this.suggestionObj.rowId = datas.row.id;
              this.suggestionObj.dialogTitle = "投诉建议详情";
              this.suggestionObj.dialogStatus = "view";
              this.suggestionObj.disposeStatus = datas.row.disposeStatus;
              this.suggestionObj.dialogVisible = true;
              break;
            case "delete":
              this.deleteHandler([datas.row.id]);
              break;
          }
        }
      },
      addNewHandler() {
        this.suggestionObj.rowId = null;
        this.suggestionObj.dialogTitle = "新增建议详情";
        this.suggestionObj.dialogStatus = "add";
        this.suggestionObj.dialogVisible = true;
      },
    },
  };
  </script>
  
  <style scoped lang="less">
  .customer-suggestion-comp {
    .filter-line {
      margin-bottom: 18px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      button {
        height: 36px;
      }
      .icon-loudoutu {
        font-size: 12px;
      }
      .header-filter-button {
        cursor: pointer;
        width: 84px;
        height: 36px;
        line-height: 36px;
        text-align: center;
        border-radius: 3px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
      }
      .filter-button-open {
        color: #409eff;
        background: #ecf5ff;
        border: 1px solid #c6e2ff;
      }
      .filter-button-close {
        color: #2a4158;
        background: #ffffff;
        border: 1px solid #e7e8eb;
      }
      .not-allowed-button {
        cursor: not-allowed;
        background-color: #fef0f0;
        border-color: #fde2e2;
        color: #f9a7a7;
      }
    }
  }
  </style>